import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Button, Typography, Image } from "antd";

const { Title, Paragraph } = Typography;
function App() {
  const { id } = useParams(); // Get the ID from the URL
  const [list, setList] = useState([]);
  const [list_org, setList_org] = useState([]);
  const linkPath = "https://reportapi.devcomputing.pro/photo/hmis_staff/";

  const getList = async () => {
    try {
      const res = await fetch(
        `https://reportapi.devcomputing.pro/api/usernameid/${id}`
      );
      if (!res.ok) {
        throw new Error(`Error: ${res.statusText}`);
      }
      const data = await res.json();
      console.log(data);
      setList(data.GetOneRecord || []); // Adjust according to your API response structure
      setList_org(data.GetOrgSetting);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };
  const backgroundImage =
    list_org.length > 0
      ? linkPath + list_org[0].imagelink
      : linkPath + "org_card_bg.jpeg";

  useEffect(() => {
    if (id) {
      getList();
      console.log("image linkg: " + backgroundImage);
    }
  }, [id]); // Fetch data when `id` changes
  // Get the first organization image URL

  return (
    <div>
      {list.map((user) => (
        <div
          style={{
            padding: "20px",
            display: "flex",
            borderRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {user && (
            <div
              style={{
                position: "relative",
              }}
            >
              {/* background cover  */}
              <img
                src={backgroundImage}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "2px",
                }}
              />
              {/* // user profile photo */}
              <img className="image-profile" src={linkPath + user.image} />
              {/* imformation of staff members */}
              <div className="div-container">
                <p style={{ fontWeight: "bold", color: "gray" }}>
                  អត្តលេខមន្ត្រី : {user.idnumber}
                </p>
                <p style={{ fontWeight: "bold", color: "gray" }}>
                  គោត្តនាម និងនាម : {user.frontname} {user.fullname}
                </p>
                <p style={{ fontWeight: "bold", color: "gray" }}>
                  ភេទ :{user.gender}
                </p>{" "}
                <p style={{ fontWeight: "bold", color: "gray" }}>
                  អង្គភាព : មន្ទីរសុខាភិបាលខេត្ត
                </p>{" "}
                <p style={{ fontWeight: "bold", color: "gray" }}>
                  ការិយាល័យ/ផ្នែក : {user.department}
                </p>{" "}
                <p style={{ fontWeight: "bold", color: "gray" }}>
                  តួនាទី : {user.position}
                </p>{" "}
                <p style={{ fontWeight: "bold", color: "gray" }}>
                  លេខទូស័ព្ទ : {user.username}
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default App;
